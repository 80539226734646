img {
  max-width: 100%;
  height: auto;
}
body {
  font-family: "Poppins", "Open Sans";
  font-weight: 400;
  color: #000000;
  overflow-x: hidden !important;
}
a {
  color: #00beff;
}
.cr-page {
  min-height: 100vh;
}
.cr-app {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  flex-flow: column;
  min-height: 100vh;
  .main-content-wrapper {
    padding-top: 66px;
    flex: 1;
    .cr-page__title {
      padding-top: 50px;
    }
    .content-wrap-inner {
      flex: 1;
    }
  }
}
.bg-light {
  background-color: #f2f3ed !important;
}
.box_structure {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.1);
  border-radius: 0;
}
.box_structure .box_body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.35rem;
}
.white-panel {
  background: $white;
  padding: 25px 30px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.15rem;
  box-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.05);
}

.main-content-wrapper {
  &.container-fluid {
    flex: 1 1 100%;
    .content-wrap-inner {
      flex: 1 1 100%;
    }
    @media screen and(min-width:992px) {
      padding-left: 98px;
      padding-right: 80px;
    }
  }
}

/* -- Button Style -- */
.btn-success {
  color: #fff !important;
  background-color: #ff5000 !important;
  border-color: #ff5000 !important;
}
.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show>.btn-success.dropdown-toggle {
  color: #fff;
  background-color: $dark-blue;
  border-color: $dark-blue;
}
.btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .show>.btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(18, 42, 114, .32);
}
.btn {
  min-width: 120px;
  border-radius: 0.15rem;
  &.btn-success {
    background-color: transparent;
    border-color: $dark-blue1;
    color: $dark-blue1;
    font-weight: 500;
    &:hover {
      background-color: $dark-blue;
      color: $white;
      border-color: $dark-blue;

    }
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(18, 42, 114, .32);
    }
  }
  &.btn-active {
    color: $white;
    background-color: #28a745;
    border-color: #28a745;
  }
  &.btn-sm {
    padding: 5px 12px;
    font-weight: 500;
    min-width: 76px;
  }
}
.btn-edit-rouned {
  border: 1px solid #ff5000;
  width: 32px;
  height: 32px;
  display: inline-block;
  line-height: 32px;
  text-align: center;
  color: #fff;
  border-radius: 50px;
  background: #fff;
  svg path {
    fill: #ff5000;
  }
  &:hover {
    background-color: $theme-color1;
    border-color: $theme-color1;
    svg path {
      fill: #fff;
    }
  }
}

/* -- tost msg -- */
.toasts-container {
  
  flex-direction: row !important;
  justify-content: center !important;
  right: 0 !important;
  left: 0 !important;
  margin: 0 auto !important;
  transform: inherit !important;
  .toast {
    //flex-basis: ;
    border-radius: 50px;
    font-size: 16px;
    padding: 5px 35px;
    z-index: 1000;

    &.toast-error {
      background-color: rgb(255, 0, 0) !important;
      color: rgb(255, 255, 255) !important;
      width: 100%;
    }
    &.toast-success {
      background-color: #27ae60 !important;
      color: rgb(255, 255, 255) !important;
      width: 100%;
    }
  }
}
.btn-edit-rouned:disabled {
  opacity: .5;
  cursor: not-allowed;
}

/* -- global color -- */
.clr-red {
  color: $red !important;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

/* -- generic header -- */
.cr-page__header {
  margin-bottom: 50px;
  .cr-page__title {
    font-size: 26px;
    font-weight: 500;
    color: #4d3d32;
    position: relative;
    padding-bottom: 0.7rem;
    display: flex;
    flex: 1;
    i {
      font-size: 28px;
      color: #ff5000;
      margin-right: 20px;
    }
    span {
      position: relative;
      padding-bottom: 12px;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 60px;
        height: 4px;
        border-radius: 50px;
        background: #ff5000;
      }
    }
  }
}

.css-1s2u09g-control{
  min-height: 51px !important;
}
.daterangepicker .drp-calendar{
  max-width: 335px !important;
}
.daterangepicker .calendar-table table{
  min-width: auto !important;
}
.daterangepicker{
  width: 688px !important;
}
// .daterangepicker{
//   width: 278px !important;
// }

/* -- generic style -- */
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}
.custom-file-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 134px;
  height: calc(2.25em + .75rem + 2px);
  padding: 13px .75rem;
  &::after {
    content: "Upload";
    background: #e9ecef
      url("data:image/svg+xml,%3Csvg fill='none' height='24' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolyline points='16 16 12 12 8 16'/%3E%3Cline x1='12' x2='12' y1='12' y2='21'/%3E%3Cpath d='M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3'/%3E%3Cpolyline points='16 16 12 12 8 16'/%3E%3C/svg%3E")
      no-repeat left 10px center;
    background-size: 18px;
    padding: 0.375rem 12px 0.375rem 34px;
    height: auto;
    padding-top: 13px;
  }
}
.file-preview {
  max-width: 100px;
  border: 1px solid #ccc;
  padding: 2px;
  margin: 0;
}
.asterisk {
  color: $red;
  font-weight: 500;
  padding-left: 3px;
}
.content_area {
  &.card {
    border-radius: 0.15rem;
  }
}
.dropdown-menu {
  border-radius: 0.15rem;
  .dropdown-item {
    font-size: 14px;
    font-weight: 500;
    &:hover {
      background-color: $theme-color1;
      color: $white;
    }
    &.active,
    &:active {
      background-color: $theme-color1;
      color: $white;
    }
  }
}

.error {
  color: $red !important;
  font-weight: 500;
  font-size: 14px;
  margin: 5px 0 0;
}
.input__icon {
  position: relative;
  &__field {
    width: 24px;
    height: 24px;
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.icon-email {
  background: url("/secure-panel/assets/images/email.svg") no-repeat center center;
  background-size: 20px;
}
.icon-pw {
  background: url("/secure-panel/assets/images/pw.svg") no-repeat center center;
  background-size: 25px;
}
.title-bor-l {
  border-left: 2px solid $theme-color1;
  padding-left: 15px;
}
strong {
  font-weight: 600;
}
.table-heading {
  padding: 30px 25px;
}

.table-container {
  table {
    margin-bottom: 0;
    td,
    th {
      border: 0;
      font-size: 14px;
      white-space: nowrap;
    }
    thead th {
      font-weight: 600;
      color: #707070;
      background: #f2f3ed;
      padding: 15px 12px;
      box-shadow: 0 -3px 0rem rgba(223, 231, 236, 0.5) inset;
    }
  }
}
.form-ui-wrapper {
  label {
    color: #000;
    font-weight: 500;
  }
  .btn {
    padding: 0.375rem 1.5rem;
  }
}
.form-control {
  height: 51px !important;
  border-color: #ccc;
  font-weight: 500;
  font-size: 13px;
  &:focus {
    box-shadow: 0 0 8px 0rem rgba(0, 0, 0, 0.10);
    border-color: #e0e0e0;
  }
}

.form-control-textbox {
  height: 51px;
  width: 100%;
  display: block;
  border-color: #ccc;
  font-weight: 500;
  font-size: 13px;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  &:focus {
    box-shadow: 0 0 8px 0rem rgba(0, 0, 0, 0.10);
    border-color: #e0e0e0;
  }
}

.form-control-for-text-box {
  height: 51px ;
  border-color: #ccc;
  font-weight: 500;
  font-size: 13px;
  &:focus {
    box-shadow: 0 0 8px 0rem rgba(0, 0, 0, 0.10);
    border-color: #e0e0e0;
  }
}

.pagination {
  display: flex;
  justify-content: flex-end;
  margin: 0;
  background: #fff;
  border-radius: 0;
  li:first-child a {
    margin-left: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  a {
    position: relative;
    display: block;
    padding: 0.35rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #122a4a;
    background-color: #fff;
    border: 1px solid #dee2e6;

    &:hover {
      text-decoration: none;
    }
  }
  li:last-child a {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  .active {
    a {
      background: #f0f9ff;
    }
  }
}

.pagination-tbl-btm {
  .pagination {
    padding: 15px 25px;
  }
}

.backBtn{
  color:#5a5a5a;
  border:1px solid #5a5a5a;
}

.backBtn:hover{
  color:#5a5a5a;
  border:1px solid #5a5a5a;
}
.modal, .modal-backdrop{
  z-index: 9999;
}

.editorHldr{
  position: absolute;
  z-index: 99;
}
.txtfld{
  height: 51px ;
}