.footer {
  text-align: center;
  .copyright{ 
    text-align: center;
    color: #00769C;
    font-size: 13px;
    line-height: 40px;
    color: rgb(32, 32, 32);
  }
}




